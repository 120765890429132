<template>
  <v-dialog v-model="dialog" persistent max-width="70%">
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar class="toolbar-color">
          <span class="headline ml-2">
            {{
              labelBtn != "Salvar"
                ? "Editar Função Nível"
                : "Cadastrar Função Nível"
            }}
          </span>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form">
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  label="ID Função"
                  v-model="formFuncaoNivel.id_funcao_nivel"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  label="Função"
                  v-model="formFuncaoNivel.funcao_nivel"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  label="Grau"
                  v-model="formFuncaoNivel.grau"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  label="Cód. Pai"
                  v-model="formFuncaoNivel.cod_pai"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-autocomplete
                  label="Multi Equipe"
                  :items="[
                    { text: 'Sim', value: 'S' },
                    { text: 'Não', value: 'N' }
                  ]"
                  :rules="[rules.required]"
                  v-model="formFuncaoNivel.mult_equipe"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close">Fechar</v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="
            labelBtn != 'Salvar' ? editarFuncaoNivel() : cadastrarFuncaoNivel()
          "
          >{{ labelBtn }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from "@/mixins/inputRules";
import sistema from "@/services/http/sistemaService";
export default {
  name: "ModalFuncaoNivel",

  mixins: [rules],

  props: {
    dialog: {
      type: Boolean,
      default: false
    },

    labelBtn: {
      type: String
    },

    funcaoNivel: {
      type: Object
    }
  },

  data() {
    return {
      formFuncaoNivel: {
        id_funcao_nivel: "",
        funcao_nivel: "",
        grau: "",
        mult_equipe: "",
        cod_pai: ""
      }
    };
  },

  watch: {
    dialog(value) {
      return value;
    },

    labelBtn(value) {
      return value;
    },

    funcaoNivel() {
      if (this.funcaoNivel) {
        this.formFuncaoNivel = this.funcaoNivel;
      }
    }
  },

  methods: {
    async cadastrarFuncaoNivel() {
      const validateStatus = this.$refs.form.validate();
      if (validateStatus) {
        await sistema()
          .funcaoNivel()
          .store(this.formFuncaoNivel, {
            notification: true,
            message: "Função Equipe cadastrado com sucesso!"
          });
        this.close();
      }
    },

    async editarFuncaoNivel() {
      const validateStatus = this.$refs.form.validate();
      if (validateStatus) {
        await sistema()
          .funcaoNivel(this.formFuncaoNivel.id_funcao_nivel)
          .update(this.formFuncaoNivel, {
            notification: true,
            message: "Funções Nivel editado com sucesso!"
          }),
          this.$notify({
            type: "success",
            text: "Funções Nivel editado"
          });

        this.close();
      }
    },

    close() {
      this.$emit("close");
      this.$refs.form.reset();
    }
  }
};
</script>

<style></style>
