<template>
  <div>
    <BaseTable
      class="table-shadow"
      :headers="headers"
      :items="infoFuncaoNivel"
      :loading="loading"
      :paginate="true"
      :search="search"
      sort-by="id_funcao_nivel"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Função Nível</v-toolbar-title>

          <v-spacer></v-spacer>
          <RegisterBottom
            v-can-access="222"
            @click="(dialog = true), (labelBtn = 'Salvar')"
          />
        </v-toolbar>
      </template>
      <template v-slot:[`item.funcao_nivel`]="{ item }">
        {{ item.funcao_nivel | TitleCase }}
      </template>
      <template v-slot:[`item.acoes`]="{ item }">
        <!-- <v-btn
          v-can-access="223"
          icon
          @click="editItem(item)"
          color="orange"
          dark
          small
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          v-can-access="225"
          icon
          @click="deleteItem(item)"
          class="ml-2 white--text"
          color="red"
          small
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>-->

        <IconBottom :name="'edit'" v-can-access="223" @click="editItem(item)" />
        <IconBottom
          :name="'delete'"
          v-can-access="225"
          @click="deleteItem(item)"
        />
      </template>
    </BaseTable>
    <ModalFuncaoNivel
      :dialog="dialog"
      :labelBtn="labelBtn"
      :funcaoNivel="funcaoNivel"
      @close="refetch()"
    />
  </div>
</template>

<script>
import IconBottom from "@/components/shared/bottons/IconBottom";
import dateMixin from "@/mixins/dateMixin";
import BaseTable from "@/components/shared/NewBaseTable.vue";
import RegisterBottom from "@/components/shared/bottons/RegisterBottom";
import ModalFuncaoNivel from "./ModalFuncaoNivel";
import sistema from "@/services/http/sistemaService";

export default {
  name: "TabelaFuncaoNivel",

  mixins: [dateMixin],

  components: {
    BaseTable,
    RegisterBottom,
    ModalFuncaoNivel,
    IconBottom
  },

  data() {
    return {
      labelBtn: "Salvar",
      dialog: false,
      funcaoNivel: {},
      loading: false,
      search: "",
      headers: [
        { text: "Função", value: "funcao_nivel" },
        { text: "Grau", value: "grau", align: "center" },
        { text: "Multi Equipe", value: "mult_equipe", align: "center" },
        { text: "Ações", value: "acoes", align: "center" }
      ],
      infoFuncaoNivel: []
    };
  },

  methods: {
    async fetchFuncaoNivel() {
      this.loading = true;
      const { data } = await sistema()
        .funcaoNivel()
        .show({
          per_page: -1
        });
      this.infoFuncaoNivel = data.data;
      this.loading = false;
    },

    editItem(item) {
      this.funcaoNivel = Object.assign({}, item);
      this.labelBtn = "Editar";
      this.dialog = true;
    },

    async deleteItem(item) {
      try {
        await this.$swal.confirm(
          "Deletar Função",
          `Deseja deletar a Fução ${item.funcao_nivel}?`
        );

        sistema()
          .funcaoNivel(item.id_funcao_nivel)
          .delete(
            {},
            {
              notification: "success",
              menssage: "Funções Nivel deletado"
            }
          );
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Erro ao deletar função"
        });
      } finally {
        this.fetchFuncaoNivel();
      }
    },
    refetch() {
      this.dialog = false;
      this.fetchFuncaoNivel();
    }
  },

  mounted() {
    this.fetchFuncaoNivel();
  }
};
</script>

<style scoped></style>
